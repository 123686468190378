import { ADVERTISER_CUSTOM_TYPE } from 'src/constants/real-estate';

export const getBankCustom = (customType?: ADVERTISER_CUSTOM_TYPE | string) => {
  const bankCustom =
    (customType === ADVERTISER_CUSTOM_TYPE.UNICREDIT ||
      customType === ADVERTISER_CUSTOM_TYPE.UBI) &&
    customType;

  return { bankCustom };
};
