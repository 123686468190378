import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { VisitRequestStep } from '../types';

import css from './styles.module.scss';

export function Actions({
  step,
  handleContinueClick,
  handleBackClick,
  handleSubmit,
  isSubmitLoading,
}: {
  step: VisitRequestStep;
  handleContinueClick: () => void;
  handleBackClick: () => void;
  handleSubmit: () => void;
  isSubmitLoading?: boolean;
}) {
  const { trans } = useTranslations();

  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  return (
    <div className={css['in-visitRequestActions']}>
      {step === 'availability' ? (
        <PepitaButton
          onClick={handleContinueClick}
          icon={{ name: 'arrow-right' }}
          iconAlignment="right"
          variant="accent"
        >
          {trans('continue_btn')}
        </PepitaButton>
      ) : (
        <>
          {!maxScreenWidthSm && (
            <PepitaButton
              onClick={handleBackClick}
              icon={{ name: 'arrow-left' }}
            >
              {trans('act_back')}
            </PepitaButton>
          )}
          <PepitaButton
            onClick={handleSubmit}
            variant="accent"
            disabled={isSubmitLoading}
            loading={isSubmitLoading}
          >
            {trans('act_send_request')}
          </PepitaButton>
        </>
      )}
    </div>
  );
}
