import { useAuthContext } from '@indomita-react/auth-provider';
import { PrivacyCheckbox } from '@indomita-react/privacy-checkbox';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import type { useVisitRequestForm } from '../hooks/useVisitRequestForm';

import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import css from './styles.module.scss';

export function VisitRequestForm({
  form,
}: {
  form: ReturnType<typeof useVisitRequestForm>;
}) {
  const { trans } = useTranslations();

  const { user } = useAuthContext();

  return (
    <fieldset className={clsx('nd-form', css['in-visitRequestForm'])}>
      <legend className="nd-form__label">
        {trans('issue_modal_your_contacts')}
      </legend>
      <PepitaTextField
        name="name"
        floatingLabel
        label={trans('lbl_name')}
        placeholder={trans('contact_form_name_placeholder', {
          capitalize: true,
        })}
        status={form.getErrorMessage('name') ? 'error' : undefined}
        statusMessage={form.getErrorMessage('name') || ''}
        onChange={form.handleChange('name')}
        value={form.data.name}
      />
      <PepitaTextField
        name="email"
        type="email"
        disabled={form.isUserEmailValid}
        floatingLabel
        label={trans('lbl_email')}
        placeholder={trans('contact_form_email_placeholder', {
          capitalize: true,
        })}
        status={form.getErrorMessage('email') ? 'error' : undefined}
        statusMessage={form.getErrorMessage('email') || ''}
        onBlur={form.handleValidateTextInput('email')}
        onChange={form.handleChange('email')}
        value={form.data.email}
      />
      <PepitaTextField
        name="phone"
        type="tel"
        floatingLabel
        label={trans('lbl_phone')}
        placeholder={trans('contact_form_phone_placeholder', {
          capitalize: true,
        })}
        status={form.isNotValid('phone') ? 'error' : undefined}
        statusMessage={form.getErrorMessage('phone') || ''}
        onChange={form.handleChange('phone')}
        onBlur={form.handleValidateTextInput('phone')}
        value={form.data.phone}
      />
      {!user && (
        <PrivacyCheckbox
          error={form.getErrorMessage('privacy') || ''}
          onChange={form.handleChange('privacy')}
          value={form.data.privacy || false}
        />
      )}
    </fieldset>
  );
}
