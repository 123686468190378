import { getProductConfig } from 'src/config/product';

import { LISTING_TYPE, REAL_ESTATE_STATES } from 'src/constants/real-estate';

import type { RealEstateKey } from 'src/types/real-estate';
import type { RealEstateLite } from 'src/types/real-estate-lite';
import type { RealEstateUserPreferences } from 'src/types/user-preferences';

export function findRealEstateByKey<V extends RealEstateKey>(
  key: RealEstateKey,
  list: V[]
) {
  return list.find((item) => item.id === key.id && item.type === key.type);
}

export function findRealEstateById(
  id: number,
  list: RealEstateUserPreferences[]
) {
  return list.find((item) => {
    return item.id === id;
  });
}

export function getGeohashLengthFromZoom(zoom: number) {
  return Math.min(8, Math.max(5, Math.floor(zoom * 0.7)));
}

export function getRealEstateCoords(data?: RealEstateLite) {
  if (!data) {
    return;
  }

  const location = data.properties[0]?.location;

  return {
    lat: location.latitude,
    lng: location.longitude,
    mrk: location.marker,
  };
}

/**
 * Convert the input value to a truthy number or return null.
 * - '20' => 20
 * - 43 => 43
 * - '0' => null
 * - 0 => null
 * - 'foo' => null
 * - undefined => null
 * - null => null
 */
export const toTruthyNumber = (
  value?: string | number | null
): number | null => {
  const v = isNaN(Number(value)) ? null : Number(value);

  return v === null || v === 0 ? null : v;
};

interface RealEstateSoldArgs {
  id: number;
  name: string;
}

export const isRealEstateSold = (state: RealEstateSoldArgs | null): boolean => {
  return REAL_ESTATE_STATES.SOLD_RENTED === state?.id;
};

export const hideSectionForRealEstateSold = (
  isRealEstateSold,
  listingType: LISTING_TYPE,
  isProjectLike: boolean
) => {
  return isRealEstateSold && listingType === LISTING_TYPE.AD && !isProjectLike;
};

export const getListMaxPages = (totalAds: number) => {
  const maxPages =
    getProductConfig('listResultMaxLabel') /
    getProductConfig('listMaxResultsPerPage');

  const pages = Math.ceil(totalAds / getProductConfig('mapMaxResultsPerPage'));

  if (pages > maxPages) {
    return maxPages;
  }

  return pages;
};

export const formatHasMore = (
  item: { number: number; hasMore: boolean } | null
) => (item ? `${item.number}${item.hasMore ? '+' : ''}` : null);

export const checkFalsyString = <S extends string>(
  item?: S | null
): S | null => {
  return item === undefined || item === null || item === '' ? null : item;
};

export const checkFalsyBoolean = (item?: boolean | null): boolean | null => {
  return item === undefined || item === null ? null : item;
};
