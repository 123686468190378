import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import { SearchSummary } from 'src/components/SaveSearch';
import { sendTrovaKasa } from 'src/components/SaveSearch/api';

import { useContactFormTracking } from '../hooks/useContactFormTracking';
import { useTrovakasaBadges } from '../hooks/useTrovakasaBadges';

import type { ContactFormData } from 'src/libs/contact';
import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog } from 'src/libs/ui/pepita-dialog';

import type { RealEstate, TrovaKasa } from 'src/types/real-estate';

import { getLocationPreview, mapTrovakasaToParams } from './utils';

interface TrovakasaDialogProps {
  onClose: () => void;
  cityId: number;
  macroZoneId: number | null | undefined;
  trovakasa: TrovaKasa;
  realEstateData: RealEstate;
  contactFormData: Partial<ContactFormData>;
}

export function TrovakasaDialog({
  onClose,
  cityId,
  macroZoneId,
  trovakasa,
  realEstateData,
  contactFormData,
}: TrovakasaDialogProps) {
  const { trans } = useTranslations();
  const [loading, setLoading] = useState(false);
  const { trackSaveSearchImpression, trackSaveSearchConfirmedTrovaKasa } =
    useContactFormTracking({ realEstateId: realEstateData.id });

  const locationPreview = getLocationPreview(
    cityId.toString(),
    macroZoneId ? macroZoneId.toString() : undefined
  );

  const handleClick = () => {
    const data = {
      ...mapTrovakasaToParams(trovakasa),
      formType: '',
      nome: contactFormData.name,
      telefono: contactFormData.phone ?? '',
      email: contactFormData.email,
      source: 'D-AN',
      getForm: 0,
      noLoginRequired: 1,
      privacy_ricerca: 'on',
      trova_immobili: 1,
      ricevi: 'on',
      invia: 'off',
    };

    setLoading(true);
    sendTrovaKasa(data).then(() => {
      trackSaveSearchConfirmedTrovaKasa();
      onClose();
    });
  };

  useEffect(() => {
    trackSaveSearchImpression();
  }, [trackSaveSearchImpression]);

  const badges = useTrovakasaBadges(trovakasa, realEstateData);

  return (
    <PepitaDialog
      disableCloseOnBackdropClick
      content={
        <>
          <strong>{trans('alert_email_similar_ads_subtitle')}</strong>
          <p>{trans('alert_email_similar_ads_text')}</p>

          <SearchSummary locationPreview={locationPreview} badges={badges} />
        </>
      }
      footer={
        <PepitaButton
          variant="accent"
          block
          onClick={handleClick}
          loading={loading}
          disabled={loading}
        >
          {trans('act_activate_for_free')}
        </PepitaButton>
      }
      title={trans('alert_email_similar_ads_title')}
      onClose={onClose}
    />
  );
}
