import { http } from '@pepita/http';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { multiContactDataAtom } from '../atoms';

import type { MultiContactResponse } from 'src/types/multi-contact';

import { getLastSearch } from '../utils';

export const useMultiContactResponse = () => {
  const multiContactData = useAtomValue(multiContactDataAtom);

  const response = useQuery({
    queryKey: [
      'multi-contact',
      multiContactData?.listingId,
      multiContactData?.email,
    ],
    enabled: Boolean(multiContactData),
    queryFn: () =>
      http
        .post(`/services/multi-contact/`, {
          json: {
            imm_ultimaRicerca: getLastSearch(),
            idAd: multiContactData?.listingId?.toString(),
            contact_email: multiContactData?.email,
          },
        })
        .json<MultiContactResponse>()
        .catch(() => {
          // do nothing.
        }),
  });

  if (response.data && response.data.ads.length > 1) {
    return {
      userDetail: multiContactData,
      showDialog: true,
      similarRealEstate: {
        ads: response.data.ads,
        countsBeforeFiltering: response.data.countsBeforeFiltering,
      },
    };
  }

  return;
};
