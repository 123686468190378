import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useVisitRequestChoices } from '../hooks/useVisitRequestChoices';
import { useVisitRequestForm } from '../hooks/useVisitRequestForm';
import { useVisitRequestTracking } from '../hooks/useVisitRequestTracking';

import type { SourceValue } from 'src/libs/contact';
import { getContactListingType } from 'src/libs/contact';
import { PepitaSnackbar, usePepitaSnackbar } from 'src/libs/ui/pepita-snackbar';

import { RegisterType } from 'src/types/auth';
import type { RealEstate } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';
import type { RealEstateLite } from 'src/types/real-estate-lite';
import type { VisitRequestStep } from '../types';

import { Actions } from '../Actions';
import { AlertBox } from '../AlertBox';
import { DaysChoice } from '../DaysChoice';
import { VisitRequestForm } from '../Form';
import { ModeChoice } from '../ModeChoice';
import { TimetablesChoice } from '../TimetablesChoice';

import css from './styles.module.scss';

type ContentProps = {
  listing?: RealEstate | ListRealEstate | RealEstateLite;
  source?: SourceValue;
  onSubmit?: ({ response, data, visitRequestData }) => void;
  step: VisitRequestStep;
  handleContinueClick: () => void;
  handleBackClick: () => void;
};

export function Content({
  listing,
  source,
  step,
  handleContinueClick,
  handleBackClick,
  onSubmit,
}: ContentProps) {
  const { trans } = useTranslations();
  const { user } = useAuthContext();

  const {
    days,
    timetables,
    mode,
    selectedDays,
    selectedTimetables,
    handleVisitMode,
    handleSelectDays,
    handleSelectTimetables,
    asSoonAsPossible,
    atAnyTime,
  } = useVisitRequestChoices();

  const agency = listing?.advertiser.agency;

  const { trackVisitRequestStep } = useVisitRequestTracking(listing?.id ?? 0);

  const setSnackbar = usePepitaSnackbar();

  const handleError = useCallback(() => {
    setSnackbar(
      <PepitaSnackbar variant="error">
        {trans('toast_generic_error_message')}
      </PepitaSnackbar>
    );
  }, [setSnackbar, trans]);

  const handleFormSubmit = useCallback(
    ({ response, data, visitRequestData }) => {
      trackVisitRequestStep({
        props: visitRequestData,
        step,
        contact: data,
      });

      const showAuthPostContact =
        isFeatureEnabled('AUTH_POST_CONTACT') &&
        !user &&
        response.registerType !== RegisterType.UNREGISTERED;

      if (!showAuthPostContact) {
        setSnackbar(
          <PepitaSnackbar variant="success">
            {trans('visit_request_sent_successfully')}
          </PepitaSnackbar>
        );
      }

      onSubmit && onSubmit({ response, data, visitRequestData });
    },
    [onSubmit, setSnackbar, step, trackVisitRequestStep, trans, user]
  );

  const form = useVisitRequestForm({
    initialData: {
      agencyId: agency?.id,
      listingId: listing?.id,
      listingType: getContactListingType(listing?.type),
      isUnicredit: agency?.customType === 'unicredit',
      source,
      originalListing:
        listing ?? ({} as RealEstate | ListRealEstate | RealEstateLite),
    },
    visitRequestData: {
      isVisitRequest: true,
      visitDays: selectedDays,
      visitTimetables: selectedTimetables,
      visitType: mode,
      asSoonAsPossible,
      atAnyTime,
    },
    advertiser: listing?.advertiser,
    contract: listing?.contract,
    properties: listing?.properties,
    agency,
    onSubmit: handleFormSubmit,
    onError: handleError,
  });

  return (
    <>
      {step === 'availability' ? (
        <>
          <ModeChoice
            type={mode}
            onChange={([modality]: string[]) => {
              handleVisitMode(modality);
            }}
            atDistanceVisitEnabled={agency?.bookableVisit?.virtualVisitEnabled}
            labelClass={css['in-visitRequestContentLabel']}
          />
          <DaysChoice
            values={selectedDays}
            onChange={handleSelectDays}
            days={days}
            multipleChoiceDays={isFeatureEnabled(
              'VISIT_REQUEST_MULTIPLE_CHOICE_DAYS'
            )}
            labelClass={css['in-visitRequestContentLabel']}
          />
          <TimetablesChoice
            values={selectedTimetables}
            onChange={handleSelectTimetables}
            timetables={timetables}
            multipleChoiceTimetables={isFeatureEnabled(
              'VISIT_REQUEST_MULTIPLE_CHOICE_TIMETABLES'
            )}
            labelClass={css['in-visitRequestContentLabel']}
          />
          <AlertBox />
        </>
      ) : (
        <VisitRequestForm form={form} />
      )}
      <Actions
        step={step}
        handleContinueClick={() => {
          trackVisitRequestStep({
            props: form.visitRequestData,
            step,
          });
          handleContinueClick();
        }}
        handleBackClick={handleBackClick}
        handleSubmit={form.handleSubmit}
        isSubmitLoading={form.status.type === 'loading'}
      />
    </>
  );
}
