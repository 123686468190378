import type { UserData } from '@indomita-react/auth-provider';

import type { LISTING_TYPE } from 'src/constants/real-estate';

import type { ContactFormData, SourceValue } from './types';

const LATEST_STORAGE_KEY = 'imm_ultimaRicerca';

const trackUnicreditContact = ({
  id,
  isProject,
}: {
  id: string | number;
  isProject: boolean;
}) => {
  const utag_data = (window.utag_data = {});

  utag_data['scheda_immobile'] = id;
  utag_data['evento'] = 'contatto_scheda';
  utag_data['tipo_annuncio'] = isProject ? 'progetto' : 'annuncio';

  if (window.utag) {
    window.utag.link(utag_data);
  }
};

// track lead conversion, after a sent lead
export const trackLeadConversion = ({ data, isUnicredit }) => {
  const {
    listingId,
    originalListing: { isProjectLike },
  } = data;

  if (isUnicredit) {
    trackUnicreditContact({
      id: listingId,
      isProject: isProjectLike,
    });
  }
};

export const getContactListingType = (type: LISTING_TYPE | undefined) => {
  if (type) {
    if (type === 'project') {
      return 'project';
    }

    return 'ad';
  }

  return undefined;
};

export const getContactSourceCode = (source?: SourceValue) => {
  switch (source) {
    case 'dettaglio_annuncio_mappa':
      return 'D-AN-MAP';

    case 'lista_annunci':
      return 'L-AN-S';

    case 'lista_agenzie':
      return 'L-AG-G';

    case 'dettaglio_annuncio_up':
      return 'D-AN-UP';

    case 'dettaglio_annuncio_down':
      return 'D-AN-DW';

    case 'dettaglio_annuncio_modal':
      return 'D-AN-MD';

    case 'dettaglio_agenzia':
      return 'D-AG-S';

    default:
      return '';
  }
};

export const getLastSearch = (): Nullable<string> => {
  const last_search: Nullable<string> =
    localStorage.getItem(LATEST_STORAGE_KEY);

  if (last_search) {
    return JSON.parse(last_search);
  }

  return null;
};

export const buildInitialData = (
  user: Nullable<UserData>,
  message?: string
): ContactFormData => {
  if (user) {
    const { name, surname, email, phone } = user;

    return {
      name: `${name} ${surname}`,
      email,
      phone: phone || '',
      privacy: true,
      message: message || '',
      sale: false,
    };
  }

  return {
    name: '',
    email: '',
    phone: '',
    privacy: false,
    message: message || '',
    sale: false,
  };
};
