import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import { PepitaStripe } from 'src/libs/ui/pepita-stripe';
import { PepitaSwitch } from 'src/libs/ui/pepita-switch';

import css from './styles.module.scss';

export type TimetableProps = {
  label: string;
  value: string;
  checked?: boolean;
  active?: boolean;
};

export type TimetablesChoiceProps = {
  timetables: TimetableProps[];
  labelClass: string;
  onChange: (newValue: string[]) => void;
  values?: string[];
  multipleChoiceTimetables?: boolean;
};

export function TimetablesChoice({
  multipleChoiceTimetables,
  timetables,
  values,
  onChange,
  labelClass,
}: TimetablesChoiceProps) {
  const { trans } = useTranslations();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  return (
    <fieldset className={css['in-visitRequestTime']}>
      <legend className={labelClass}>{trans('choose_time_preference')}</legend>
      {maxScreenWidthSm ? (
        <PepitaStripe customClass={css['in-visitRequestTime__stripe']} spaced>
          {timetables.map((time) => (
            <PepitaStripe.Slide
              customClass={css['in-visitRequestTime__item']}
              key={time.value}
            >
              <PepitaSwitch
                inputType={multipleChoiceTimetables ? 'checkbox' : 'radio'}
                values={values}
                onChange={onChange}
              >
                <PepitaSwitch.Item
                  key={time.value}
                  value={time.value}
                  name="timetables"
                  disabled={!time.active}
                  readOnly
                  customClass={css['in-visitRequestTime__switchItem']}
                >
                  {time.label}
                </PepitaSwitch.Item>
              </PepitaSwitch>
            </PepitaStripe.Slide>
          ))}
        </PepitaStripe>
      ) : (
        <PepitaSwitch
          customClass={css['in-visitRequestTime__switch']}
          inputType={multipleChoiceTimetables ? 'checkbox' : 'radio'}
          values={values}
          onChange={onChange}
        >
          {timetables.map((time) => (
            <PepitaSwitch.Item
              key={time.value}
              value={time.value}
              name="timetables"
              disabled={!time.active}
              readOnly
              customClass={css['in-visitRequestTime__switchItem']}
            >
              {time.label}
            </PepitaSwitch.Item>
          ))}
        </PepitaSwitch>
      )}
    </fieldset>
  );
}
