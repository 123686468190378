/**
 * Calculates the difference in days between two UNIX dates expressed in seconds
 *
 *
 * @param endDate is the earliest data
 * @param startDate is the nearest data (If not set, it defaults to the current date)
 * @returns the difference (in day) between the two dates
 */
export function dayDiff(
  endDate: number,
  startDate: Nullable<number> = null
): number {
  const start = startDate
    ? new Date(startDate * 1000).getTime()
    : new Date().getTime();
  const end = new Date(endDate * 1000).getTime();

  return Math.round((start - end) / (24 * 3600 * 1000));
}

// return date in 'yyyy-mm-dd hh:mm:ss' format
export function formatDate(date: Date): string {
  const [first, last] = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  )
    .toISOString()
    .split('T');

  return `${first} ${last.substring(0, 8)}`;
}
