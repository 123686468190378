import { atom } from 'jotai';

import type { MultiContactDataAtom } from 'src/types/multi-contact';

export enum ContactSourcePage {
  LISTING_DETAIL,
  SEARCH_LIST,
  SEARCH_MAP,
  SEARCH_MAP_SAVED,
  AGENCY_DETAIL,
  AGENCIES_LIST,
}
export const contactSourcePageAtom = atom<ContactSourcePage | undefined>(
  undefined as unknown as ContactSourcePage
);

export const multiContactDataAtom = atom<MultiContactDataAtom | undefined>(
  undefined as unknown as MultiContactDataAtom
);

export const trovakasaDialogHiddenAtom = atom<boolean>(false);
