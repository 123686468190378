import type { BaseSyntheticEvent, ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { trovakasaUserDataAtom } from '../atoms';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import { isFeatureEnabled } from 'src/config/features-toggle';

import type { CONTRACT } from 'src/constants/real-estate';

import type { ContactFormData, ContactFormInitialData } from 'src/libs/contact';
import {
  buildInitialData,
  getShowTrovakasa,
  multiContactDataAtom,
  saveSuggested,
  sendLead,
  trackLeadConversion,
  trovakasaDialogHiddenAtom,
  useContactFormSchema,
  useContactFormStatus,
} from 'src/libs/contact';

import { setGA4trackingData } from 'src/tracking/ga/utils/store';
import { hashEmailAndPhone, storeLastEmailAndPhone } from 'src/tracking/utils';

import type { Agency } from 'src/types/agency';
import { RegisterType } from 'src/types/auth';
import type { Advertiser, Property, Socials } from 'src/types/real-estate';
import type { TrovaKasa } from 'src/types/real-estate';
import type { ListProperty } from 'src/types/real-estate-list';
import type { PropertyLite } from 'src/types/real-estate-lite';
import type { SendLeadResponse } from 'src/types/send-lead';
import type { Language } from 'src/types/translations';

import {
  getValidationErrors,
  isFieldInvalid,
  validateField,
  validateFields,
} from 'src/utils/form-validators';
import { getMulticontactUserSkipPreference } from '../utils';

const socialsEnabledOnProduct = isFeatureEnabled('SOCIALS_ENABLED');

export function useContactForm(
  initialData: ContactFormInitialData,
  advertiser?: Advertiser,
  contract?: CONTRACT,
  properties?: (Property | ListProperty | PropertyLite)[],
  socials?: Socials,
  onSubmit?: (contactData: Partial<ContactFormData>) => void,
  agency?: Agency,
  onContactClose?: () => void,
  trovakasa?: TrovaKasa,
  dataType?: 'real-estate' | 'list-real-estate' | 'real-estate-lite'
) {
  const { trans } = useTranslations();
  const { user } = useAuthContext();

  const { locale, defaultLocale } = useRouter();
  const setTrovakasaDialogHidden = useSetAtom(trovakasaDialogHiddenAtom);
  const mainPropertyId = properties?.[0].id;

  const socialsEnabled = Boolean(user) && socials && socialsEnabledOnProduct;

  const { message } = initialData;

  const [data, setData] = useState<ContactFormData>(
    buildInitialData(user, message)
  );

  useEffect(() => {
    const data = buildInitialData(user, message);

    setData(data);
  }, [user, message]);

  const [status, setStatus] = useContactFormStatus();

  // this is used to show the message "contact again" enabled only when socials is enabled in the product
  const [leadSent, setLeadSent] = useState(false);
  const [leadSentBox, setLeadSentBox] = useState(false);

  const schema = useContactFormSchema();

  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const setTrovakasaUserData = useSetAtom(trovakasaUserDataAtom);

  const setAuthModal = useSetAtom(authModalAtom);
  const setMultiContactData = useSetAtom(multiContactDataAtom);
  const authModalClose = () => {
    setAuthModal({ open: false });
    onContactClose?.();
  };

  function handleSubmit() {
    if (status.type === 'loading') return;

    const errors = validateFields(data, schema);

    if (errors) {
      setStatus({
        type: 'error',
        errors,
      });

      return;
    }

    setStatus('loading');

    const { agencyId, listingId, listingType, isUnicredit, source } =
      initialData;

    const promise = sendLead({
      ...(data as ContactFormData),
      agencyId,
      listingId,
      listingType,
      isUnicredit,
      source,
      mobile: maxScreenWidthSm,
      lang: (locale || defaultLocale) as Language,
      estateId:
        listingId?.toString() !== mainPropertyId ? mainPropertyId : undefined,
    });

    async function handleSuccess(response: SendLeadResponse) {
      if (socialsEnabledOnProduct) {
        setLeadSent(true);
        setLeadSentBox(true);

        // reset privacy value for unlogged user
        if (!user) {
          setData({ ...data, privacy: false });
        }
      }

      trackLeadConversion({
        data: {
          ...initialData,
          contract,
          advertiser,
          properties,
          agency,
        },
        isUnicredit,
      });

      if (
        isFeatureEnabled('AUTH_POST_CONTACT') &&
        !user &&
        response.registerType &&
        response.registerType !== RegisterType.UNREGISTERED
      ) {
        setAuthModal({
          open: true,
          data: {
            email: data.email,
            alertMessage: trans('lbl_socials_new_message_sent_agency'),
            additionalTrackingData: {
              'Entry Point': 'Modale Login Richiesta Contatto',
            },
          },
          onClose: authModalClose,
          onSuccess: authModalClose,
          showAdvantageInfo: true,
        });
      }

      setStatus({
        type: 'success',
        message: trans('msg_sended_succes'),
      });

      const showTrovakasaModal =
        source && listingId
          ? await getShowTrovakasa(
              { source, realEstateId: listingId },
              Boolean(response.showTrovakasaLink)
            )
          : false;

      const isMultiContact =
        trovakasa &&
        !getMulticontactUserSkipPreference() &&
        isFeatureEnabled('SHOW_MULTI_CONTACT_FORM') &&
        dataType === 'real-estate';

      if (showTrovakasaModal) {
        setTrovakasaUserData({ email: data.email, name: data.name });
      }

      if (isMultiContact) {
        if (!showTrovakasaModal) {
          setTrovakasaDialogHidden(true);
        }

        setMultiContactData({
          listingId,
          privacy: data.privacy,
          email: data.email,
          phone: data.phone,
          name: data.name,
          sale: data.sale,
        });
      }

      if (!user) {
        if (data.email || data.phone) {
          setGA4trackingData({
            user: {
              ...hashEmailAndPhone({ email: data.email, phone: data.phone }),
            },
          });
          storeLastEmailAndPhone({ email: data.email, phone: data.phone });
        }
      }

      onSubmit?.({ ...data, contactId: response.contactId });
    }

    function handleError() {
      setStatus({
        type: 'error',
        errors: [],
        message: trans('toast_generic_error_message'),
      });
    }

    promise.then(handleSuccess, handleError);

    /** Save suggested searches
     * listingId is possible undefined only on generic agency contact
     * in this case we don't call suggested searches because its mandatory for the call
     *  @see: https://gitlab.pepita.io/immobiliare/site-v7/-/blob/develop/site/src/Immobiliare/AppBundle/Controller/SearchController.php#L279
     */
    if (listingId) {
      saveSuggested({
        listingId,
        listingType,
        email: data.email,
      });
    }
  }

  const handleValidateTextInput =
    (name: keyof ContactFormData) => (evt: BaseSyntheticEvent) => {
      const value = evt.target.value;

      const error = validateField(name, data, schema);

      if (status.type === 'error') {
        const errors = validateFields(data, schema);
        const prev = new Set(status.errors.map((e) => e.path));

        if (value && errors) {
          setStatus({
            type: 'error',
            errors: (error || []).concat(
              errors.filter((e) => prev.has(e.path))
            ),
          });
        }
      } else if (value && error) {
        setStatus({
          type: 'error',
          errors: error,
        });
      }
    };

  const handleChange =
    (name: keyof ContactFormData) =>
    (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value =
        evt.target.type === 'checkbox'
          ? (evt.target as HTMLInputElement).checked
          : evt.target.value;

      const newData = {
        ...data,
        [name]: value,
      };

      setData(newData);

      // We only remove the errors during change
      if (status.type === 'error') {
        const errors = validateFields(newData, schema);

        if (!errors) {
          setStatus('ok');
        } else {
          const prev = new Set(status.errors.map((e) => e.path));

          setStatus({
            type: 'error',
            errors: errors.filter((e) => prev.has(e.path)),
          });
        }
      }
    };

  function isNotValid(name: keyof ContactFormData) {
    return status.type === 'error' && isFieldInvalid(status.errors, name);
  }

  function getErrorMessage(name: keyof ContactFormData): string | undefined {
    return (
      (isNotValid(name) &&
        status.type === 'error' &&
        getValidationErrors(status.errors, name)) ||
      undefined
    );
  }

  return {
    status,
    setStatus,
    handleSubmit,
    handleChange,
    handleValidateTextInput,
    isNotValid,
    getErrorMessage,
    data,
    leadSent,
    leadSentBox,
    hideLeadSentBox: () => setLeadSentBox(false),
    socialsEnabled,
  };
}
