import { useTranslations } from '@pepita-react/i18n';

import { getProductConfig } from 'src/config/product';

import type { ContactFormData } from '../types';

import type { ValidationSchema, Validator } from 'src/utils/form-validators';
import {
  isEmail,
  isPhoneNumber,
  isPhoneOrEmailRequired,
  isRequired,
  isTruthy,
  phoneWhenEmail,
} from 'src/utils/form-validators';

const { emailRequired, phoneRequired } = getProductConfig('lead');

export const useContactFormSchema = () => {
  const { trans } = useTranslations();

  const handleEmailValidation = () => {
    const emailValidation: Validator<ContactFormData>[] = [
      isEmail(trans('insert_valid_email', { capitalize: true })),
    ];

    if (emailRequired) {
      emailValidation.push(
        isRequired(
          trans('contact_form_email_error_message', { capitalize: true })
        )
      );
    } else if (!phoneRequired) {
      emailValidation.push(
        isPhoneOrEmailRequired(trans('contact_form_email_phone_error_message'))
      );
    }

    return emailValidation;
  };

  const handlePhoneValidation = () => {
    const phoneValidation: Validator<ContactFormData>[] = [
      isPhoneNumber(trans('contact_form_phone_error_message')),
    ];

    if (phoneRequired) {
      phoneValidation.push(
        isRequired(
          trans('contact_form_phone_error_message', { capitalize: true })
        )
      );
    } else if (!emailRequired) {
      phoneValidation.push(phoneWhenEmail());
    }

    return phoneValidation;
  };

  const contactFormSchema: ValidationSchema<ContactFormData> = {
    name: [
      isRequired(trans('name_field_validation_msg', { capitalize: true })),
    ],
    privacy: [isTruthy(trans('act_declare_consent'))],
    email: handleEmailValidation(),
    phone: handlePhoneValidation(),
  };

  return contactFormSchema;
};
