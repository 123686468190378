import { useState } from 'react';

import { useVisitRequestTracking } from './hooks/useVisitRequestTracking';

import type { SourceValue } from 'src/libs/contact';

import type { RealEstate } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';
import type { RealEstateLite } from 'src/types/real-estate-lite';

import type { VisitRequestButtonProps } from './VisitRequestButton';
import { VisitRequestButton } from './VisitRequestButton';
import { VisitRequestDialog } from './VisitRequestDialog';

export type VisitRequestProps = {
  listing?: RealEstate | ListRealEstate | RealEstateLite;
  /**
   * The variable that must be passed to the send-lead api
   */
  source?: SourceValue;
  withLandingDialog?: boolean;
  onSubmit?: ({ response, data, visitRequestData }) => void;
} & VisitRequestButtonProps;

export function VisitRequest({
  listing,
  source,
  withLandingDialog,
  onSubmit,
  text,
  customClass,
  buttonProps,
}: VisitRequestProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const { trackVisitRequestButtonClick } = useVisitRequestTracking(
    listing?.id ?? 0
  );

  const handleVisitRequestButtonClick = (evt) => {
    evt.preventDefault();
    setIsOpen(true);
    if (listing?.id) {
      trackVisitRequestButtonClick();
    }
  };

  return (
    <>
      <VisitRequestButton
        onClick={(evt) => {
          handleVisitRequestButtonClick(evt);
        }}
        text={text}
        customClass={customClass}
        buttonProps={buttonProps}
      />
      {isOpen && (
        <VisitRequestDialog
          listing={listing}
          source={source}
          withLandingDialog={withLandingDialog}
          onClose={handleOnClose}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
