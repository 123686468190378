import type { GA4StoreRealEstateTrackingData } from 'src/tracking/ga/types';
import {
  getGA4RealEstateListTrackingData,
  getGA4RealEstateTrackingData,
} from 'src/tracking/ga/utils/store';

export const getContactFormTrackingData = (
  section: 'list' | 'detail' | 'map',
  realEstateId?: number
) => {
  // first scenario is when we are in list page and we want to get the tracking data of a specific real estate
  // we're interacting with
  if (section === 'list') {
    const { realEstateTrackingData: realEstateGA4TrackingData } =
      getGA4RealEstateListTrackingData()?.find(
        (re) => re.realEstateTrackingData?.id === realEstateId
      ) ?? ({} as GA4StoreRealEstateTrackingData);

    return realEstateGA4TrackingData;
  }

  // in this scenario we are in detail/map page
  const { realEstateTrackingData: realEstateGA4TrackingData } =
    getGA4RealEstateTrackingData();

  return realEstateGA4TrackingData;
};
