import { useCallback, useState } from 'react';

import { useVisitRequestAvailability } from './useVisitRequestAvailability';

import type {
  VisitRequestDayProps,
  VisitRequestTimetableProps,
} from 'src/types/real-estate';

import type { ModeChoiceProps } from '../ModeChoice';
import type { TimetableProps } from '../TimetablesChoice';

const getSelectedChoices = (
  items: VisitRequestDayProps[] | VisitRequestTimetableProps[]
) => items.filter((item) => item.checked).map((item) => item.value);

const selectChoices = (
  values: string[],
  prevValues: string[],
  defaultValue: string
) => {
  const defaultChoice =
    prevValues.length === 1 && prevValues[0] === defaultValue;

  // select/deselect the default value
  // when user selects specific choice or not
  if (defaultChoice) {
    // Previous selection contains default value
    return values.length > 1
      ? values.filter((value: string) => value !== defaultValue)
      : [defaultValue];
  }

  return !values.length || values.includes(defaultValue)
    ? [defaultValue]
    : values;
};

export const useVisitRequestChoices = () => {
  const { days, timetables } = useVisitRequestAvailability();

  const [mode, setMode] = useState<ModeChoiceProps['type']>('real');

  const [selectedDays, setSelectedDays] = useState<string[]>(
    getSelectedChoices(days)
  );

  const [selectedTimetables, setSelectedTimetables] = useState<string[]>(
    getSelectedChoices(timetables)
  );

  const asSoonAsPossible =
    selectedDays.length === 1 &&
    selectedDays[0] === 'visit_as_soon_as_possible';

  const atAnyTime =
    selectedTimetables.length === 1 &&
    selectedTimetables[0] === 'visit_at_any_time';

  const handleVisitMode = useCallback((modality: string) => {
    setMode(modality as ModeChoiceProps['type']);
  }, []);

  const handleSelectDays = useCallback((values: string[]) => {
    setSelectedDays((prevDays) =>
      selectChoices(values, prevDays, 'visit_as_soon_as_possible')
    );
  }, []);

  const handleSelectTimetables = useCallback(
    (values: TimetableProps['value'][]) => {
      setSelectedTimetables((prevTimes) =>
        selectChoices(values, prevTimes, 'visit_at_any_time')
      );
    },
    []
  );

  return {
    days,
    timetables,
    mode,
    selectedDays,
    selectedTimetables,
    handleVisitMode,
    handleSelectDays,
    handleSelectTimetables,
    asSoonAsPossible,
    atAnyTime,
  };
};
