import { atom } from 'jotai';

import type { LISTING_TYPE } from 'src/constants/real-estate';

export type VisitRequestSent = {
  showContactBox: boolean;
  id: number;
  type: LISTING_TYPE;
};

// atom which contains the ids of all the properties with a visit request just sent
export const visitRequestsSentAtom = atom<VisitRequestSent[]>([]);
