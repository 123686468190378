import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAtom, useSetAtom } from 'jotai';

import { trovakasaUserDataAtom } from '../atoms';

import { trovakasaDialogHiddenAtom } from 'src/libs/contact';
import {
  PepitaDialog,
  PepitaDialogContentFrameType,
  PepitaDialogFrameType,
} from 'src/libs/ui/pepita-dialog';

import { http } from 'src/utils/client/http';
import { getCssModuleClassNames } from '../utils';

import type { ContactFormProps } from '../index';
import { ContactForm } from '../index';
import { TrovakasaDialog } from '../TrovakasaDialog';

import css from '../index.module.scss';

const cssModuleClassNames = getCssModuleClassNames(css);

export function ContactFormDialog({
  title,
  onClose,
  data,
  agency,
  source,
  textareaPrefilledValue,
  textareaPlaceholder,
  trovakasa,
  onSubmitContact,
  onShowPhoneClick,
}: ContactFormProps & {
  title: string;
  onClose: () => void;
}) {
  /**
   * As we do not want to open the contact form without socials data, we
   * choose to defer the opening until that data will be fetched from the api.
   * So during the fetching, we will have the "opening" state, which will be
   * replaced with the "open" state when
   * the socials api will be fetched
   * N.B. We avoid calling the api when real estate data is missing (for agency cards)
   */
  const [modalState, setModalState] = useState<'open' | 'opening'>(
    data ? 'opening' : 'open'
  );

  const { data: socials, isFetching } = useQuery({
    queryKey: ['socials', data?.type, data?.id],
    queryFn: () =>
      http.get(`/api-next/socials/${data?.type}/${data?.id}/`).json(),
    enabled: modalState === 'opening',
  });

  useEffect(() => {
    if (modalState === 'opening' && !isFetching) {
      // Api has finished, we can open the dialog
      setModalState('open');
    } else if (isFetching) {
      setModalState('opening');
    }
  }, [modalState, isFetching]);

  const [trovakasaDialog, setTrovakasaDialog] = useState(false);
  const [trovakasaUserData, setTrovakasaUserData] = useAtom(
    trovakasaUserDataAtom
  );

  const setTrovakasaDialogHidden = useSetAtom(trovakasaDialogHiddenAtom);

  const handleClose = () => {
    if (trovakasa && trovakasaUserData) {
      return setTrovakasaDialog(true);
    }

    if (!trovakasaUserData) {
      setTrovakasaDialogHidden(true);
    }

    return onClose();
  };

  return (
    <>
      {trovakasaUserData &&
        trovakasa &&
        trovakasaDialog &&
        data &&
        data.dataType === 'real-estate' && (
          <TrovakasaDialog
            onClose={() => {
              setTrovakasaUserData(undefined);
              onClose();
            }}
            cityId={data.properties[0].location.cityId}
            macroZoneId={data.properties[0].location.macrozoneId}
            realEstateData={data}
            trovakasa={trovakasa}
            contactFormData={trovakasaUserData}
          />
        )}

      {modalState === 'open' && (
        <PepitaDialog
          title={title}
          onClose={handleClose}
          size={PepitaDialogFrameType.MEDIUM}
          contentSize={PepitaDialogContentFrameType.FULL}
          content={
            <ContactForm
              data={data}
              agency={agency}
              socials={
                Object.keys(socials || {})?.length === 0 ? null : socials
              }
              source={source}
              textareaPrefilledValue={textareaPrefilledValue}
              textareaPlaceholder={textareaPlaceholder}
              onSubmitContact={onSubmitContact}
              onShowPhoneClick={onShowPhoneClick}
              trovakasa={trovakasa}
              onContactClose={handleClose}
            />
          }
          frameCustomClass={cssModuleClassNames('in-lead__frame')}
        />
      )}
    </>
  );
}
