import { getProductConfig } from 'src/config/product';

import { CONTRACT } from 'src/constants/real-estate';
import { CATEGORIES } from 'src/constants/typologies';

import { ENERGY_RATE } from 'src/types/energy-rate';
import type { TrovaKasa, Typology } from 'src/types/real-estate';
import type { Language } from 'src/types/translations';

import type { Trans } from 'src/utils/i18nFormatters';
import { currencyFormatter } from 'src/utils/i18nFormatters';
import {
  getAlternateLocationPreviewUrl,
  getCityLocationPreviewUrl,
} from 'src/utils/locationPreview';
import { getRoomsValue } from 'src/utils/searchBadges';

export const getLocationPreview = (cityId: string, macroZoneId?: string) => {
  const macrozone = macroZoneId ? `/${macroZoneId}` : '';

  return {
    main: `${getCityLocationPreviewUrl(cityId, '328x109', '3')}${macrozone}`,
    alternate: getAlternateLocationPreviewUrl(),
  };
};

export const getGeographyInfoBadge = (
  trans: Trans,
  city: string,
  macrozone?: string
) =>
  macrozone
    ? `${city} ${trans('search_formatter_with_zone_selected', {
        count: 1,
        params: [1],
      })}`
    : city;

export const getTypologyBadge = (
  trans: Trans,
  typology: Typology | undefined,
  categoryId: number,
  contract: CONTRACT,
  contractValue: string | undefined
) => {
  if (
    typology &&
    contractValue &&
    categoryId.toString() !== CATEGORIES.STANZE &&
    categoryId.toString() !== CATEGORIES.NEGOZI_LOCALI_COMMERCIALI
  ) {
    return trans('print_ad_title', {
      params: [typology.name, contractValue],
    });
  }

  if (contract === CONTRACT.RENT) {
    return trans('generic_house_rent');
  }

  return trans('generic_house_sell');
};

export const getPriceRangeBadge = (
  trans: Trans,
  lang: Language,
  minPrice?: number,
  maxPrice?: number
) => {
  const min = minPrice && currencyFormatter(trans, lang, minPrice, '€');
  const max = maxPrice && currencyFormatter(trans, lang, maxPrice, '€');

  if (min && max) {
    return `${min} - ${max}`;
  }

  if (max) {
    return trans('search_formatter_max', { params: [max] });
  }

  if (min) {
    return trans('search_formatter_min', { params: [min] });
  }

  return null;
};

export const getPropertyTypeBadge = (trans: Trans, id: number) => {
  const propertyType = [
    { id: 1, value: 'entire_property' }, // i18n-extract-keys ["entire_property"]
    { id: 5, value: 'bare_property' }, // i18n-extract-keys ["bare_property"]
    { id: 9, value: 'partial_property' }, // i18n-extract-keys ["partial_property"]
    { id: 13, value: 'lbl_usufruct' }, // i18n-extract-keys ["lbl_usufruct"]
    { id: 17, value: 'fractional_ownership' }, // i18n-extract-keys ["fractional_ownership"]
    { id: 21, value: 'land_lease' }, // i18n-extract-keys ["land_lease"]
  ];

  const propertyTypeValue = propertyType.find((property) => property.id === id)
    ?.value;

  // i18n-extract-disable-next-line
  return propertyTypeValue ? trans(propertyTypeValue) : null;
};

export const getRoomsBadge = (trans: Trans, min?: string, max?: string) =>
  getRoomsValue(
    trans,
    {
      uniqueValue: 'search_formatter_nr_rooms', // i18n-extract-keys ["search_formatter_nr_rooms"]
      bothValues: 'search_formatter_nr_rooms_min_max', // i18n-extract-keys ["search_formatter_nr_rooms_min_max"]
      onlyMin: 'listing_meta_max_room', // i18n-extract-keys ["listing_meta_max_room"]
      onlyMax: 'listing_meta_min_room', // i18n-extract-keys ["listing_meta_min_room"]
    },
    min,
    max
  );

export const getAuctionBadge = (
  trans: Trans,
  contract: CONTRACT,
  inAuction: number
) =>
  (contract === CONTRACT.AUCTION || inAuction) &&
  trans('detail_auction', { capitalize: true });

export const getEnergyRange = (energyRating: string) => {
  if (
    energyRating === 'E' ||
    energyRating === 'Ε' ||
    energyRating === 'F' ||
    energyRating === 'Ζ' ||
    energyRating === 'G' ||
    energyRating === 'Η'
  ) {
    return '8';
  } else if (
    energyRating === 'B' ||
    energyRating === 'C' ||
    energyRating === 'Γ' ||
    energyRating === 'D' ||
    energyRating === 'Δ'
  ) {
    return '5';
  } else if (energyRating.includes('A')) {
    return '2';
  }

  return '';
};

export const getEnergyRangeBadge = (trans: Trans, energyClass: string) => {
  const energyEfficiency = getEnergyRange(energyClass);
  const energy2String = {};

  for (const energyRate in ENERGY_RATE) {
    energy2String[ENERGY_RATE[energyRate]] = energyRate;
  }

  const translationKey =
    getProductConfig('name') === 'indomio-es'
      ? 'Indomio.es_energy_rating'
      : 'Immobiliare.it_energy_rating';

  // i18n-extract-keys ["Immobiliare.it_energy_rating_high", "Immobiliare.it_energy_rating_medium", "Immobiliare.it_energy_rating_low"]
  // i18n-extract-keys ["Indomio.es_energy_rating_high", "Indomio.es_energy_rating_medium", "Indomio.es_energy_rating_low"]

  return energyEfficiency !== ''
    ? // i18n-extract-disable-next-line
      `${trans('insertAd.energyRating')} - ${trans(translationKey, {
        context: energy2String[energyEfficiency],
      })}`
    : null;
};

export const mapTrovakasaToParams = (trovakasa: TrovaKasa) => ({
  fkNazione: trovakasa.nation,
  regione: trovakasa.regionId,
  provincia: trovakasa.provinceId,
  comune: trovakasa.cityId,
  areaGeografica: trovakasa.geographyAreaId,
  zona: trovakasa.zoneId,
  vacanze: trovakasa.holiday,
  contratto: trovakasa.contractId,
  categoria: trovakasa.categoryId,
  tipologia: trovakasa.typologyId,
  sottotipologia: trovakasa.subTypologyId,
  fkStato: trovakasa.buildingStateId,
  bagni: trovakasa.bathrooms,
  aReddito: trovakasa.income,
  tipoProprieta: trovakasa.propertyTypeId,
  riscaldamento: trovakasa.heatingId,
  prezzoMin: trovakasa.minPrice,
  prezzoMax: trovakasa.maxPrice,
  supMin: trovakasa.minSurface,
  supMax: trovakasa.maxSurface,
  locMin: trovakasa.minLocals,
  locMax: trovakasa.maxLocals,
  boxauto: trovakasa.boxAutoId,
  giardino: trovakasa.garden,
  balcone: trovakasa.balcony,
  terrazzo: trovakasa.terrace,
  inAsta: trovakasa.inAuction,
  noAste: trovakasa.noAuction,
  animali: trovakasa.animals,
  fumatore: trovakasa.smoker,
  categoriaStanza: trovakasa.roomsCategoryId,
  idTipologiaStanza: trovakasa.roomsTypologyId,
  classeEnergetica: trovakasa.energyClassId,
  ascensore: trovakasa.elevator,
  verticaleAste: trovakasa.auctionSection,
  arredato: trovakasa.furnished,
});
