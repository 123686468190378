import type { EffectCallback } from 'react';
import { useEffect, useState } from 'react';

export function useDebouncedEffect(
  callback: EffectCallback,
  triggers: any[] = [], // eslint-disable-line @typescript-eslint/no-explicit-any
  duration = 1000
) {
  const [state, setState] = useState<Optional<Record<string, never>>>();

  useEffect(() => {
    const timer = setTimeout(() => {
      setState({});
    }, duration);

    return () => clearTimeout(timer);
  }, [setState, duration].concat(triggers));

  useEffect(() => {
    if (!state) return;

    return callback();
  }, [state]);

  return state;
}
