import { useCallback } from 'react';

import { useTrack } from 'src/tracking/hooks/useTrack';
import { getSegmentTrackingData } from 'src/tracking/segment/utils';
import { getRealEstateSectionFromEntryPoint } from 'src/tracking/utils';
import {
  EventName,
  PurchaseAndContactEventValue,
} from 'src/tracking/utils/enums';
import type {
  TrackRequestVisitStepProps,
  TrackRequestVisitSubmitArgs,
} from '../tracking/types';

import { useRealEstateTracking } from 'src/views/RealEstate/hooks/useRealEstateTracking';

export const useVisitRequestTracking = (realEstateId: number) => {
  const track = useTrack();
  const { handleContact, handlePurchase } = useRealEstateTracking({
    section: getRealEstateSectionFromEntryPoint(),
    realEstateId,
  });

  const trackingData =
    getRealEstateSectionFromEntryPoint() === 'list'
      ? getSegmentTrackingData().realEstateList?.find(
          (re) => re['ID Listing'] === realEstateId
        )
      : getSegmentTrackingData().realEstate;

  const trackVisitRequestButtonClick = useCallback(() => {
    track(EventName.VISIT_REQUEST_MODAL_SUBMIT, trackingData);
  }, [track, trackingData]);

  const trackRequestVisitSubmit = useCallback(
    ({
      contactId,
      email,
      phone,
      contactSubtype,
    }: TrackRequestVisitSubmitArgs) => {
      if (contactId) {
        handlePurchase(PurchaseAndContactEventValue.VISIT, contactId);
        handleContact(PurchaseAndContactEventValue.VISIT, contactSubtype, {
          contactId,
          email,
          phone,
        });
      }
    },
    [handlePurchase, handleContact]
  );

  const formatRequestVisitStep = useCallback(
    ({ props, step, contact }: TrackRequestVisitStepProps) => {
      if (!props || !step) return;

      const eventName =
        step === 'availability'
          ? EventName.VISIT_REQUEST_STEP_ONE
          : EventName.VISIT_REQUEST_STEP_TWO;

      const alwaysAvailable: string[] = [];

      if (props.asSoonAsPossible) {
        alwaysAvailable.push('Day');
      }

      if (props.atAnyTime) {
        alwaysAvailable.push('Time');
      }

      return {
        eventName,
        trackingData: {
          'Sender Name': contact?.name,
          'Sender Email': contact?.email,
          'Sender Phone Number': contact?.phone,
          'Visit mode selection':
            props.visitType === 'real' ? 'Di persona' : 'A distanza',
          '# of days selected': props.asSoonAsPossible
            ? 0
            : props.visitDays?.length,
          'Day selection': props.asSoonAsPossible ? undefined : props.visitDays,
          '# of times selected': props.atAnyTime
            ? 0
            : props.visitTimetables?.length,
          'Time selection': props.atAnyTime ? undefined : props.visitTimetables,
          'Always available': alwaysAvailable,
          ...trackingData,
        },
      };
    },
    [trackingData]
  );

  const trackVisitRequestStep = useCallback(
    ({ props, step, contact }: TrackRequestVisitStepProps) => {
      const trackingProps = formatRequestVisitStep({
        props,
        step,
        contact,
      });

      if (!trackingProps) {
        return;
      }

      track(trackingProps.eventName, trackingProps.trackingData);
    },
    [formatRequestVisitStep, track]
  );

  return {
    trackVisitRequestButtonClick,
    trackRequestVisitSubmit,
    trackVisitRequestStep,
  };
};
