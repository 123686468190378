import type { MouseEvent } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import type { PepitaButtonProps } from 'src/libs/ui/pepita-button';
import { PepitaButton } from 'src/libs/ui/pepita-button';

export interface VisitRequestButtonProps {
  text?: string;
  customClass?: string;
  buttonProps?: PepitaButtonProps;
  onClick?: (evt?: MouseEvent) => void;
}

export function VisitRequestButton({
  text,
  customClass,
  buttonProps,
  onClick,
}: VisitRequestButtonProps) {
  const { trans } = useTranslations();

  const getText = () => {
    if (text) {
      return text;
    }

    return trans('act_visit');
  };

  return (
    <PepitaButton
      type="button" // prevents automatic onSubmit on forms in which it's used
      icon={{ name: 'calendar' }}
      customClass={customClass}
      onClick={onClick}
      {...buttonProps}
    >
      {getText()}
    </PepitaButton>
  );
}
