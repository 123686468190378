import { useTranslations } from '@pepita-react/i18n';

import type {
  VisitRequestDayProps,
  VisitRequestTimetableProps,
} from 'src/types/real-estate';

interface UseVisitRequestAvailabilityReturnProps {
  days: VisitRequestDayProps[];
  timetables: VisitRequestTimetableProps[];
}

export const useVisitRequestAvailability =
  (): UseVisitRequestAvailabilityReturnProps => {
    const { trans } = useTranslations();

    //getDate() Returns the day of the month (1 – 31) for the specified date according to local time.
    //getDay() Returns the day of the week (0 – 6) for the specified date according to local time starting from sunday = 0
    //getMonth() Returns the month (0 – 11) in the specified date according to local time.
    const daysFromToday: VisitRequestDayProps[] = [];

    for (let i = 1; i <= 14; i++) {
      const incrementalDay = new Date();

      incrementalDay.setDate(incrementalDay.getDate() + i);
      const dayNumber = incrementalDay.getDay();
      const newDay: VisitRequestDayProps = {
        // i18n-extract-disable-next-line
        label: trans(
          `week_day_${dayNumber === 0 ? '7' : dayNumber.toString()}`
        ).substring(0, 3),
        monthDay: incrementalDay.getDate().toString(),
        value: incrementalDay.toISOString().split('T')[0],
        // i18n-extract-disable-next-line
        monthName: trans(
          `lbl_month_${(incrementalDay.getMonth() + 1).toString()}`
        ).substring(0, 3),
        checked: false,
        active: true,
      };

      daysFromToday.push(newDay);
    }

    const days = [
      {
        label: trans('visit_as_soon_as_possible'),
        value: 'visit_as_soon_as_possible',
        checked: true,
        active: true,
      },
      ...daysFromToday,
    ];

    const timetables = [
      {
        label: trans('visit_at_any_time'),
        value: 'visit_at_any_time',
        checked: true,
        active: true,
      },
      { label: '9-12', value: '9-12', checked: false, active: true },
      { label: '12-14', value: '12-14', checked: false, active: true },
      { label: '14-17', value: '14-17', checked: false, active: true },
      { label: '17-20', value: '17-20', checked: false, active: true },
    ];

    return {
      days,
      timetables,
    };
  };
