import { useAtomValue } from 'jotai';

import { ContactSourcePage, contactSourcePageAtom } from '../atoms';

import type { ContactSource } from '../types';

import { getContactSourceCode } from '../utils';

export const useContactSource = (): ContactSource => {
  const contactSourcePage = useAtomValue(contactSourcePageAtom);

  switch (contactSourcePage) {
    case ContactSourcePage.AGENCY_DETAIL:
      return {
        label: 'dettaglio_agenzia',
        code: getContactSourceCode('dettaglio_agenzia'),
      };

    case ContactSourcePage.SEARCH_LIST:
      return {
        label: 'lista_annunci',
        code: getContactSourceCode('lista_annunci'),
      };

    case ContactSourcePage.SEARCH_MAP:
    case ContactSourcePage.SEARCH_MAP_SAVED:
      return {
        label: 'dettaglio_annuncio_mappa',
        code: getContactSourceCode('dettaglio_annuncio_mappa'),
      };

    case ContactSourcePage.AGENCIES_LIST:
      return {
        label: 'lista_agenzie',
        code: getContactSourceCode('lista_agenzie'),
      };

    default:
      return {
        label: undefined,
        code: '',
      };
  }
};
