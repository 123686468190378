import { useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { bannersDataAtom } from 'src/atoms/bannersDataAtom';

import { geographySearchParamsAtom } from 'src/components/GeographySearch/atoms';

import { realEstateSearchParamsAtom } from 'src/hooks/useRealEstateLiteSearch';

import { http } from 'src/utils/client/http';

import { agencyListQueryParamsAtom } from 'src/views/AgencyList/atoms/agencyListSearchParamsAtom';
import { getUseQueryKey } from 'src/views/AgencyList/utils/getUseQueryKey';

// TODO Con la 0.3 di state-atom questo lo posso fare nella funzione di setup dell'atom
// https://gitlab.pepita.io/pepita/pepita-frontend/state-atom#effects
export function useBanners(isAgencyListSearch = false) {
  const setBannersData = useSetAtom(bannersDataAtom);

  // Don't fetch banners on change of map bounds and zoom
  const { minLat, maxLat, minLng, maxLng, zoom, ...paramsRealEstateSearch } =
    useAtomicStateAtomValue(realEstateSearchParamsAtom);

  const geographySearchParams = useAtomicStateAtomValue(
    geographySearchParamsAtom
  );
  const paramsAgencyListQuery = useAtomicStateAtomValue(
    agencyListQueryParamsAtom
  );

  const params = useMemo(() => {
    if (isAgencyListSearch) {
      // Nel caso della lista agenzia la categoria da passare è statica e sempre la 7
      return {
        ...geographySearchParams,
        ...paramsAgencyListQuery,
        idCategoria: '7',
      };
    }

    return paramsRealEstateSearch;
  }, [
    isAgencyListSearch,
    geographySearchParams,
    paramsAgencyListQuery,
    paramsRealEstateSearch,
  ]);

  const { data } = useQuery({
    queryKey: getUseQueryKey('real-estate-list-banners', params),
    queryFn: () =>
      http
        .get('/api-next/real-estate/banners/', {
          searchParams: {
            service: 'banners',
            ...params,
          },
        })
        .json(),
  });

  useEffect(() => {
    // Nella prima ricerca i dati vengono precaricati lato server
    // quindi saltiamo il primo fetch assumendo di avere già i dati
    // Tutto questo lo facciamo per anticipare il più possibile il caricamento
    // delle pubblicità in modo da diminiuire i layout shift in pagina
    if (!data) {
      return;
    }

    setBannersData(data);
  }, [data, setBannersData]);
}
