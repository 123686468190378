import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { Manager } from 'src/components/Manager';
import { Referent } from 'src/components/Referent';

import { PepitaAlert } from 'src/libs/ui/pepita-alert';

import type { AgencyLite, PhoneTypes, Supervisor } from 'src/types/real-estate';

import { VisitRequestSentBox } from '../SentBox';
import { VisitRequestButton } from '../VisitRequestButton';

import css from './styles.module.scss';

interface LandingProps {
  visitJustSent: boolean;
  onClick: () => void;
  onShowPhoneClick?: (phoneTypes: PhoneTypes) => void;
  label?: string;
  agency?: AgencyLite;
  supervisor?: Supervisor;
}

export function Landing({
  visitJustSent,
  label,
  agency,
  supervisor,
  onClick,
  onShowPhoneClick,
}: LandingProps) {
  const { trans } = useTranslations();

  return (
    <>
      <div className={css['in-visitRequestLanding__feedback']}>
        {visitJustSent ? (
          <VisitRequestSentBox />
        ) : (
          label && <PepitaAlert variant="info">{label}</PepitaAlert>
        )}

        <VisitRequestButton
          onClick={onClick}
          buttonProps={{
            block: true,
          }}
          text={trans('act_request_new_visit')}
        />
      </div>
      <div
        className={clsx(
          'in-visitRequestLanding__content--albatross',
          'in-visitRequestLanding__contentData'
        )}
      >
        {/* utente|agente|delegato */}
        {supervisor && (
          <Manager
            supervisor={supervisor}
            onShowPhoneClick={onShowPhoneClick}
          />
        )}
        {/* agenzia */}
        {agency && (
          <Referent
            agency={agency}
            supervisor={supervisor}
            customType={agency.customType}
            link
            onShowPhoneClick={onShowPhoneClick}
          />
        )}
      </div>
    </>
  );
}
