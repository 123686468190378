import { atom } from 'jotai';
import type { AgencyListQueryParams } from 'src/repository/agencies/types';

import { createAtomicStateStateAtom } from 'src/atoms/atomic-state';

export const agencyListQueryParamsAtom =
  createAtomicStateStateAtom<AgencyListQueryParams>({
    key: 'agencyListQueryParamsAtom',
    default: {} as AgencyListQueryParams,
    deepEqualityCheck: true,
  });

export const agencyListFormStateAtom =
  createAtomicStateStateAtom<AgencyListQueryParams>({
    key: 'agencyListFormStateAtom',
    default: {},
    setup: (self, { watch, set, get }) => {
      watch(() => {
        set(self, get(agencyListQueryParamsAtom));
      }, [agencyListQueryParamsAtom]);
    },
  });

export const agencyListServerSearchAtom = atom<boolean>(true);
