import { capitalize, useTranslations } from '@pepita-react/i18n';

import { PepitaStripe } from 'src/libs/ui/pepita-stripe';
import { PepitaSwitch } from 'src/libs/ui/pepita-switch';

import css from './styles.module.scss';

export interface DayProps {
  label: string;
  monthName?: string;
  monthDay?: string;
  value: string;
  checked?: boolean;
  active?: boolean;
}

export interface DaysChoiceProps {
  days: DayProps[];
  onChange: (newValue: string[]) => void;
  labelClass: string;
  values?: string[];
  multipleChoiceDays?: boolean;
}

export function DaysChoice({
  multipleChoiceDays,
  days,
  values,
  onChange,
  labelClass,
}: DaysChoiceProps) {
  const { trans } = useTranslations();

  return (
    <fieldset className={css['in-visitRequestCalendar']}>
      <legend className={labelClass}>
        {trans('choose_your_availabilities')}
      </legend>
      <PepitaStripe customClass={css['in-visitRequestCalendar__stripe']} spaced>
        {days.map((day) => (
          <PepitaStripe.Slide
            customClass={css['in-visitRequestCalendar__item']}
            key={day.value}
          >
            <PepitaSwitch
              inputType={multipleChoiceDays ? 'checkbox' : 'radio'}
              values={values}
              onChange={onChange}
            >
              <PepitaSwitch.Item
                customClass={css['in-visitRequestCalendar__content']}
                value={day.value}
                name="days"
                disabled={!day.active}
                readOnly
              >
                {day.label && (
                  <span className={css['in-visitRequestCalendar__text']}>
                    {capitalize(day.label)}
                  </span>
                )}
                {day.monthDay && (
                  <span className={css['in-visitRequestCalendar__day']}>
                    {day.monthDay}
                  </span>
                )}
                {day.monthName && (
                  <span className={css['in-visitRequestCalendar__text']}>
                    {capitalize(day.monthName)}
                  </span>
                )}
              </PepitaSwitch.Item>
            </PepitaSwitch>
          </PepitaStripe.Slide>
        ))}
      </PepitaStripe>
    </fieldset>
  );
}
