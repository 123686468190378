import placeholder from '@pepita-fe/sprite-placeholder-manager/sprite.svg';
import clsx from 'clsx';

import { AgencyPhone } from 'src/components/AgencyPhone';
import { PrivatePhone } from 'src/components/PrivatePhone';

import { SUPERVISOR_TYPE } from 'src/constants/real-estate';

import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';
import {
  PepitaMediaObject,
  PepitaMediaObjectContent,
} from 'src/libs/ui/pepita-media-object';

import type { PhoneTypes, Supervisor } from 'src/types/real-estate';

import { capitalize } from 'src/utils/string';

import { LinkOrHtmlTag } from '../LinkOrHtmlTag';

import css from './styles.module.scss';

interface ManagerProps {
  supervisor: Supervisor;
  onShowPhoneClick?: (phoneTypes: PhoneTypes) => void;
  customClass?: string;
}

export function Manager({
  supervisor,
  onShowPhoneClick,
  customClass,
}: ManagerProps) {
  const supervisorPhones = supervisor.phones;

  const imagePlaceholder = (
    <PepitaIcon
      name={`${supervisor.imageType}_${supervisor.imageGender}`}
      spriteUrl={placeholder}
      customClass={css['in-manager__placeholder']}
    />
  );

  const showPrivateNoName =
    supervisor.label &&
    supervisor.type === SUPERVISOR_TYPE.PRIVATE_USER &&
    !supervisor.displayName;

  return (
    <PepitaMediaObject
      customClass={clsx(css['in-manager'], customClass)}
      padded
    >
      <PepitaFigure
        ratio="square"
        bordered
        figureContent={supervisor.imageUrl || imagePlaceholder}
        customClass={css['in-manager__figure']}
        alt={supervisor.displayName || capitalize(supervisor.label)}
      />
      <PepitaMediaObjectContent>
        {/* when the block isn't inside the form and the supervisor is of delegate type and has a role */}
        {showPrivateNoName && (
          <p
            className={clsx(
              css['in-manager__text'],
              css['in-manager__text--dark']
            )}
          >
            {capitalize(supervisor.label)}
          </p>
        )}

        {supervisor.displayName && (
          <LinkOrHtmlTag
            tag="p"
            url={supervisor.url}
            target="_blank"
            className={css['in-manager__text']}
          >
            {supervisor.displayName}
          </LinkOrHtmlTag>
        )}
        {supervisor.type === 'user' ? (
          <PrivatePhone phoneImageUrl={supervisor.phoneUrl} />
        ) : (
          supervisorPhones.length > 0 &&
          supervisorPhones.map((phone) => (
            <AgencyPhone
              key={phone.value}
              type={phone.type}
              phoneNumber={phone.value}
              onShowPhone={() => onShowPhoneClick?.(phone.type)}
            />
          ))
        )}
      </PepitaMediaObjectContent>
    </PepitaMediaObject>
  );
}
