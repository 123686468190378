import type { ClassArray } from 'clsx';
import { clsx } from 'clsx';

import { isDev } from 'src/utils/environment';

const MULTICONTACT_SKIP_SESSION_KEY = '__multicontact_skip__';

export const goToThreadPage = (threadUrl: Nullable<string> = null) => {
  const url = threadUrl ? threadUrl : '/utente/messaggi/';

  window.open(url);
};

export function getCssModuleClassNames(module: Record<string, string>) {
  return (...classes: ClassArray) =>
    clsx(classes)
      .split(' ')
      .map((name) => {
        if (isDev) {
          if (!module[name]) {
            // eslint-disable-next-line no-console
            console.warn(`The className "${name}" is missing in this module`);
          }
        }

        return module[name];
      })
      .join(' ');
}

export const getMulticontactUserSkipPreference = () => {
  let preference;
  const skipMultiContact = localStorage.getItem(MULTICONTACT_SKIP_SESSION_KEY);

  if (skipMultiContact) {
    preference = JSON.parse(skipMultiContact);
  }

  if (!preference || new Date().getTime() > preference.expireAt) {
    localStorage.removeItem(MULTICONTACT_SKIP_SESSION_KEY);

    return false;
  }

  return preference.skip;
};
