import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { trovakasaUserDataAtom } from '../atoms';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import { trovakasaDialogHiddenAtom } from 'src/libs/contact';

import type { ContactFormProps } from '..';
import { ContactForm } from '..';
import { TrovakasaDialog } from '../TrovakasaDialog';

import css from '../index.module.scss';

interface ContactFormInPageProps extends ContactFormProps {
  trackingKey?: string;
}

export function ContactFormInPage({
  trackingKey,
  ...contact
}: ContactFormInPageProps) {
  const authModal = useAtomValue(authModalAtom);
  const [trovakasaUserData, setTrovakasaUserData] = useAtom(
    trovakasaUserDataAtom
  );

  const setTrovakasaDialogHidden = useSetAtom(trovakasaDialogHiddenAtom);

  const { data, trovakasa } = contact;

  return (
    <>
      {trovakasaUserData &&
        trovakasa &&
        authModal.open === false &&
        data?.dataType === 'real-estate' && (
          <TrovakasaDialog
            onClose={() => {
              setTrovakasaUserData(undefined);
              setTrovakasaDialogHidden(true);
            }}
            cityId={data.properties[0].location.cityId}
            macroZoneId={data.properties[0].location.macrozoneId}
            realEstateData={data}
            trovakasa={trovakasa}
            contactFormData={trovakasaUserData}
          />
        )}

      <div className={css['in-lead--inPage']} data-tracking-key={trackingKey}>
        <ContactForm {...contact} />
      </div>
    </>
  );
}
