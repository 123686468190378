type Tag = keyof JSX.IntrinsicElements;

interface LinkOrHtmlTagProps {
  url?: string;
  tag: Exclude<Tag, 'a'>;
  className?: string;
  target?: HTMLAnchorElement['target'];
  children: React.ReactNode;
}

export function LinkOrHtmlTag({
  className,
  children,
  url,
  tag: Tag,
  target,
}: LinkOrHtmlTagProps) {
  if (url) {
    return (
      <a className={className} href={url} target={target}>
        {children}
      </a>
    );
  }

  return <Tag className={className}>{children}</Tag>;
}
