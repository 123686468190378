import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaSwitch } from 'src/libs/ui/pepita-switch';

import css from './styles.module.scss';

export type ModeChoiceProps = {
  type: 'virtual' | 'real';
  onChange: (newValue: string[]) => void;
  labelClass: string;
  atDistanceVisitEnabled?: boolean;
};

export function ModeChoice({
  atDistanceVisitEnabled,
  type,
  onChange,
  labelClass,
}: ModeChoiceProps) {
  const { trans } = useTranslations();

  return (
    <fieldset>
      <legend className={labelClass}>{trans('choose_how_to_visit')}</legend>
      <PepitaSwitch
        customClass={css['in-visitRequestModeChoice']}
        inputType="radio"
        values={type ? [type] : []}
        onChange={onChange}
        grouped
      >
        <PepitaSwitch.Item
          icon={{
            name: 'person',
          }}
          value="real"
          name="visitType"
          readOnly
        >
          {trans('in_person_visit_request')}
        </PepitaSwitch.Item>
        <PepitaSwitch.Item
          icon={{
            name: 'video',
          }}
          value="virtual"
          name="visitType"
          readOnly
          disabled={!atDistanceVisitEnabled}
          customClass={
            !atDistanceVisitEnabled
              ? css['in-visitRequestModeChoice__withTooltip']
              : undefined
          }
        >
          {atDistanceVisitEnabled ? (
            trans('remote_visit_request')
          ) : (
            <>
              <span className={css['in-visitRequestModeChoice__tooltipText']}>
                {trans('remote_visit_request')}
              </span>
              <div
                className={clsx(
                  css['in-visitRequestModeChoice__tooltip'],
                  'nd-tooltip__container',
                  'nd-tooltip__container--dark',
                  'nd-tooltip__container--toBottom',
                  'nd-tooltip__container--multiRow'
                )}
              >
                {trans('remote_visit_not_available')}
              </div>
            </>
          )}
        </PepitaSwitch.Item>
      </PepitaSwitch>
    </fieldset>
  );
}
