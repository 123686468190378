import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import css from './styles.module.scss';

enum Styles {
  phone = 'in-privatePhone',
  icon = 'in-privatePhone__icon',
}

interface PrivatePhoneProps {
  phoneImageUrl?: Nullable<string>;
}

//For the user phone, we insert an image instead of the real number because, for privacy reasons,
//we avoid that the number can be searched on the internet and remain in the cache
export function PrivatePhone({ phoneImageUrl }: PrivatePhoneProps) {
  if (!phoneImageUrl) {
    return null;
  }

  const phone = (
    <>
      <PepitaIcon name="phone" customClass={css[Styles.icon]} />
      <img loading="lazy" src={phoneImageUrl} alt="" />
    </>
  );

  return <p className={css[Styles.phone]}>{phone}</p>;
}
