import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { useQuery } from '@tanstack/react-query';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useVisitRequest } from '../hooks/useVisitRequest';

import type { SourceValue } from 'src/libs/contact';
import { getContactSourceCode } from 'src/libs/contact';
import {
  PepitaDialog,
  PepitaDialogContentFrameType,
  PepitaDialogFrameType,
} from 'src/libs/ui/pepita-dialog';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { getRealEstateSectionFromEntryPoint } from 'src/tracking/utils';

import type { PhoneTypes, RealEstate } from 'src/types/real-estate';
import type { ListRealEstate } from 'src/types/real-estate-list';
import type { RealEstateLite } from 'src/types/real-estate-lite';

import { http } from 'src/utils/client/http';

import { useRealEstateTracking } from 'src/views/RealEstate/hooks/useRealEstateTracking';

import { Content } from '../Content';
import { Landing } from '../Landing';

import css from './styles.module.scss';

interface VisitRequestDialogProps {
  listing?: RealEstate | ListRealEstate | RealEstateLite;
  source?: SourceValue;
  withLandingDialog?: boolean;
  onSubmit?: ({ response, data, visitRequestData }) => void;
  onClose: () => void;
}

export function VisitRequestDialog({
  listing,
  source,
  withLandingDialog = false,
  onSubmit,
  onClose,
}: VisitRequestDialogProps) {
  const { trans } = useTranslations();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const { id, type, advertiser, visibility, price } = listing || {};

  const { trackRealEstatePhoneContact } = useRealEstateTracking({
    section: getRealEstateSectionFromEntryPoint(),
    realEstateId: id ?? 0,
  });

  /**
   * As we do not want to open the visit request landing dialog without socials data, we
   * choose to defer the opening until that data will be fetched from the api.
   * So during the fetching, we will have the "opening" state, which will be
   * replaced with the "open" state when
   * the socials api will be fetched
   * N.B. We avoid calling the api when real estate data is missing
   */
  const [modalState, setModalState] = useState<'open' | 'opening'>(
    listing ? 'opening' : 'open'
  );

  // social query is enabled only with
  // withLandingDialog param and SOCIALS_ENABLED on product
  const { data: socials, isFetching } = useQuery({
    queryKey: ['socials', type, id],
    queryFn: () => http.get(`/api-next/socials/${type}/${id}/`).json(),
    enabled:
      modalState === 'opening' &&
      withLandingDialog &&
      isFeatureEnabled('SOCIALS_ENABLED'),
  });

  const {
    handleBackClick,
    handleSuccess,
    showLanding,
    setShowLanding,
    step,
    setStep,
    isVisitJustSent,
  } = useVisitRequest(
    undefined,
    id && type
      ? {
          id,
          type,
        }
      : undefined,
    onClose,
    onSubmit,
    withLandingDialog,
    socials?.withVisit
  );

  useEffect(() => {
    if (modalState === 'opening' && !isFetching) {
      // Api has finished, we can open the dialog
      setModalState('open');
    } else if (isFetching) {
      setModalState('opening');
    }
  }, [modalState, isFetching, setModalState]);

  /**
   * opens the landing modal instead of the visit request modal
   * in case of visit just sent|retrieved from socials
   */

  useEffect(() => {
    if (socials?.withVisit) setShowLanding(true);
  }, [setShowLanding, socials]);

  return (
    <>
      {modalState === 'open' ? (
        <PepitaDialog
          title={
            <>
              {maxScreenWidthSm && step === 'contact' && (
                <button
                  onClick={handleBackClick}
                  className={css['in-visitRequestDialog__backIcon']}
                >
                  <PepitaIcon name="arrow-left" />
                </button>
              )}
              <span className="nd-dialogFrame__title">
                {trans('act_request_visit', {
                  capitalize: true,
                })}
              </span>
            </>
          }
          onClose={onClose}
          size={PepitaDialogFrameType.MEDIUM}
          contentSize={
            showLanding ? PepitaDialogContentFrameType.FULL : undefined
          }
          content={
            showLanding ? (
              <Landing
                visitJustSent={isVisitJustSent}
                label={socials?.label}
                onClick={() => {
                  setShowLanding(false);
                }}
                agency={advertiser?.agency}
                supervisor={advertiser?.supervisor}
                onShowPhoneClick={(phoneTypes: PhoneTypes) => {
                  // toresu track
                  if (id) {
                    trackRealEstatePhoneContact({
                      phoneType: phoneTypes,
                      source: getContactSourceCode(source),
                      advertiser,
                      visibility: visibility || null,
                      price,
                    });
                  }
                }}
              />
            ) : (
              <Content
                listing={listing}
                source={source}
                step={step}
                handleContinueClick={() => {
                  setStep('contact');
                }}
                handleBackClick={handleBackClick}
                onSubmit={({ response, data, visitRequestData }) =>
                  handleSuccess({
                    response,
                    data,
                    visitRequestData,
                  })
                }
              />
            )
          }
        />
      ) : null}
    </>
  );
}
