import type { Reducer } from 'react';
import { useReducer } from 'react';

import type { ContactFormData } from '../types';

import type { ValidationError } from 'src/utils/form-validators';

export type ContactFormStatusState =
  | {
      type: 'ok' | 'loading' | 'trovakasa';
    }
  | {
      type: 'success';
      message?: string;
    }
  | {
      type: 'multiContact';
      ads: any[];
      countsBeforeFiltering: number;
    }
  | {
      type: 'error';
      errors: ValidationError<ContactFormData>[];
      message?: string;
    };

export type ContactFormStatusAction =
  | 'ok'
  | 'loading'
  | 'success'
  | 'trovakasa'
  | {
      type: 'success';
      message: string;
    }
  | {
      type: 'error';
      errors: ValidationError<ContactFormData>[];
      message?: string;
    }
  | {
      type: 'multiContact';
      ads: any[];
      countsBeforeFiltering: number;
    };

export const useContactFormStatus = () => {
  return useReducer<Reducer<ContactFormStatusState, ContactFormStatusAction>>(
    (_, action) => {
      if (typeof action === 'string') {
        return {
          type: action,
        };
      }

      return action;
    },
    { type: 'ok' }
  );
};
