import type { RefObject } from 'react';
import { useEffect } from 'react';

export const useIntersectionObserver = <T extends HTMLElement>({
  ref,
  onChange,
  margin,
  enabled,
  root,
}: {
  ref: RefObject<T>;
  onChange: (isIntersecting: boolean) => void;
  margin?: () => number;
  enabled?: boolean;
  root: 'offsetParent' | 'document';
}) => {
  useEffect(() => {
    if (!enabled) return;

    if (typeof IntersectionObserver === 'undefined') {
      onChange(true);

      return;
    }

    const el = ref.current;

    /**
     * Be careful with that
     *
     * If the IntersectionObserver instances will become too many they could generate some perf issues
     *
     * In that case switch to a solution like this --> https://gitlab.pepita.io/pepita/pepita-frontend/frontend-packages/blob/4096ba8403c796403545b6602b17d834750b685c/packages/tiny-lit-element/src/Observer.ts
     */
    const Observer = new IntersectionObserver(
      (event) => {
        onChange(event[0].isIntersecting);
      },
      {
        root: root === 'offsetParent' ? el?.offsetParent : undefined,
        rootMargin: margin ? `${margin()}px 0px ${margin()}px 0px` : undefined,
      }
    );

    Observer.observe(el as Element);

    return () => {
      Observer.disconnect();
    };
  }, [enabled]);
};
